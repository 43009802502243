<template>
  <div class="material-index">
    <my-search-bar>
      <el-form :inline="true">
        <!-- <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.meterageDisplayCode"
            placeholder="子目号"
            clearable>
          </el-input>
        </el-form-item>
        -->
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">刷新</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      class="mt-3"
      :data="materialList"
      border
      header-cell-class-name="bg-info text-light"
      >
      <el-table-column type="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="materialName" header-align="center" align="center" label="材料名称"></el-table-column>
      <el-table-column prop="materialSpec" header-align="center" align="center" label="规格"></el-table-column>
      <el-table-column prop="unit" header-align="center" align="center" label="单位"></el-table-column>
      <el-table-column prop="sortId" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column width="80" label="操作" header-align="center" align="center">
        <template slot-scope="scope">
          <i @click="handleEditClick(scope.row)" class="text-info far fa-edit operation-button mr-2"></i>
          <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog
      ref="myDialog"
      :title="dialogTitle"
      size="md"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleOk"
      >
      <el-form :model="material" label-width="120px">
        <el-form-item label="材料名称">
          <el-input v-model="material.materialName"></el-input>
        </el-form-item>
        <el-form-item label="规格">
          <el-input v-model="material.materialSpec"></el-input>
        </el-form-item>
        <el-form-item label="单位">
          <el-input v-model="material.unit"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model.number="material.sortId"></el-input>
        </el-form-item>
      </el-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import G11Model from '@/model/G11Model'
import PageOperation from '@/mixins/PageOperation'
import { mapGetters } from 'vuex'
import auth from '@/common/auth'
import axios from 'axios'
import config from '@/config'
import utility from '@/common/utility'

export default {
  name: 'G11Index',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar
  },
  filters: {
  },
  computed: {
    userGuid () {
      return auth.getUserInfo().userGuid
    },
    userIdentityGuid () {
      return auth.getUserInfo().identityGuid
    },
    couldLock () {
      return config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) === -1
    },
    firstYear () {
      // TODO
      return this.year === 2020
    },
    showAddBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid && config.applyIdentities.indexOf(this.userIdentityGuid) > -1
    },
    showEditBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid
    },
    dataColumns () {
      if (this.materialList.length) {
        return this.materialList[0].children
      }
      return []
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  data () {
    return {
      testValue: 1,
      total: 0,
      materialList: [],
      materialModel: new G11Model(),
      material: {
        materialGuid: utility.getUuid(),
        materialName: '',
        materialSpec: '',
        unit: '',
        sortId: 1
      },
      searchModel: {
        planStatExaminationGuid: '',
        meterageDisplayCode: ''
      },
      selectedValues: [],
      totalAmount: '',
      dialogTitle: ''
    }
  },
  methods: {
    numberFormatter (row, column) {
      return row[column.property] === 0 ? '' : row[column.property]
    },
    cellClassName ({ row, column }) {
      if (!row.empty && column.property === 'totalMonthPercent' && parseFloat(row[column.property]) > 100) {
        return 'warning'
      }
      return ''
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除该材料吗')
        .then(() => {
          axios.delete(`${config.restHost}/material`, {
            params: {
              materialGuid: row.materialGuid
            }
          })
            .then(res => {
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '操作成功！'
                })
                this._getMaterialList()
              } else {
                this.$message({
                  type: 'error',
                  message: '操作失败！'
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '操作失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作！'
          })
        })
    },
    classFilter (column) {
      if (column.identityGuid === this.userIdentityGuid && this.userGuid === this.approvalUserGuid) {
        return 'editable'
      }
      return ''
    },
    handleBatchPicture (flag) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      if (flag) {
        // open image selection ui
        this.$refs.myDialog.open()
      } else {
        this.doBatchPicture('')
      }
    },
    handleOk () {
      if (!this.material.resourceId) {
        axios.post(`${config.restHost}/material`, this.material, {
        })
          .then(res => {
            this.$refs.myDialog.close()
            if (res.data.code === 1) {
              this.$message({
                type: 'success',
                message: '操作成功！'
              })
              this._getMaterialList()
            } else {
              this.$message({
                type: 'error',
                message: '操作失败！'
              })
            }
          })
          .catch(err => {
            console.log(err)
            this.$message({
              type: 'error',
              message: '操作失败！'
            })
          })
      } else {
        axios.put(`${config.restHost}/material`, this.material, {
        })
          .then(res => {
            this.$refs.myDialog.close()
            if (res.data.code === 1) {
              this.$message({
                type: 'success',
                message: '操作成功！'
              })
              this._getMaterialList()
            } else {
              this.$message({
                type: 'error',
                message: '操作失败！'
              })
            }
          })
          .catch(err => {
            console.log(err)
            this.$message({
              type: 'error',
              message: '操作失败！'
            })
          })
      }
    },
    handleBlur (row, type = 0) {
      if (type) {
        console.log('materialIdentity', row)
        this._approval(row)
      } else {
        this.materialModel = new G11Model(row)
        this.materialModel.currentMonthNum = parseFloat(row.currentMonthNum)
        this._edit()
      }
    },
    handleSearchClick () {
      this._getMaterialList()
    },
    handleAddClick () {
      this.material = {
        resourceId: 0,
        materialGuid: utility.getUuid(),
        materialName: '',
        materialSpec: '',
        unit: '',
        sortId: 1,
        tenderGuid: auth.getUserInfo().tenderGuid
      }
      this.dialogTitle = '添加材料信息'
      this.$refs.myDialog.open()
    },
    handleEditClick (row) {
      this.material = row
      this.material.resourceId = 1
      this.dialogTitle = '编辑材料信息'
      this.$refs.myDialog.open()
    },
    _getMaterialList () {
      this.$myLoading()
      axios.get(`${config.restHost}/material`, {
        params: {
          tenderGuid: auth.getUserInfo().tenderGuid
        }
      })
        .then(res => {
          this.$loading().close()
          if (res.data.code === 1) {
            this.materialList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: '列表加载失败！'
            })
          }
        })
        .catch(err => {
          this.$loading().close()
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    }
  },
  created () {
    this._getMaterialList()
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
::v-deep .editable {
  color: red;
}
::v-deep .warning {
  background-color: red;
}
</style>
